import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, createElementBlock as _createElementBlock, normalizeClass as _normalizeClass, withCtx as _withCtx, createVNode as _createVNode } from "vue"

const _hoisted_1 = { class: "statcard-wrapper" }
const _hoisted_2 = { class: "statcard-content" }
const _hoisted_3 = { class: "p-0" }
const _hoisted_4 = { class: "p-0 my-2" }
const _hoisted_5 = { class: "title-1" }
const _hoisted_6 = {
  key: 1,
  class: "ml-2 mt-8 flex flex-row flex-wrap justify-content-between vertical-stat-headers"
}
const _hoisted_7 = { class: "" }
const _hoisted_8 = { class: "qty" }
const _hoisted_9 = { class: "title-1" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_LoadingSpinner = _resolveComponent("LoadingSpinner")!
  const _component_Card = _resolveComponent("Card")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_Card, {
      class: _normalizeClass({ 'statcard-selected': _ctx.selected })
    }, {
      content: _withCtx(() => [
        _createElementVNode("div", _hoisted_2, [
          _createElementVNode("div", _hoisted_3, [
            _createElementVNode("h3", _hoisted_4, _toDisplayString(_ctx.title), 1),
            _createElementVNode("span", _hoisted_5, _toDisplayString(_ctx.subtitle), 1)
          ]),
          (_ctx.isLoading)
            ? (_openBlock(), _createBlock(_component_LoadingSpinner, {
                key: 0,
                loading: _ctx.isLoading
              }, null, 8, ["loading"]))
            : (_openBlock(), _createElementBlock("div", _hoisted_6, [
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.statInfo.data, (value, index) => {
                  return (_openBlock(), _createElementBlock("div", {
                    key: index,
                    class: "vertical-stat-headers-item mt-2"
                  }, [
                    _createElementVNode("div", _hoisted_7, [
                      _createElementVNode("div", _hoisted_8, _toDisplayString(value.value), 1),
                      _createElementVNode("div", _hoisted_9, _toDisplayString(value.label), 1)
                    ])
                  ]))
                }), 128))
              ]))
        ])
      ]),
      _: 1
    }, 8, ["class"])
  ]))
}