import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveDynamicComponent as _resolveDynamicComponent, createBlock as _createBlock, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = { class: "w-full grid m-0 p-0 px-1 top-statcards-wrapper" }
const _hoisted_2 = ["onClick"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_router_link = _resolveComponent("router-link")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.items, (card, index) => {
      return (_openBlock(), _createElementBlock("div", {
        key: card.label,
        class: _normalizeClass(["xl:col-4 xl:block hidden col-12 m-0 p-1 pl-2 pr-1", { block: _ctx.isSelected(card.to) }]),
        onClick: ($event: any) => (_ctx.handleTabChange(index))
      }, [
        _createVNode(_component_router_link, {
          to: card.to,
          class: "no-underline"
        }, {
          default: _withCtx(() => [
            (_openBlock(), _createBlock(_resolveDynamicComponent(card.component), {
              ref_for: true,
              ref: "topStatCard",
              card: card,
              selected: _ctx.isSelected(card.to),
              title: card.label,
              subtitle: card.subtitle,
              "onRow:click": _ctx.handleRowClick,
              getter: card.getter,
              amountArray: card.amountArray,
              statInfo: card.statInfo
            }, null, 40, ["card", "selected", "title", "subtitle", "onRow:click", "getter", "amountArray", "statInfo"]))
          ]),
          _: 2
        }, 1032, ["to"])
      ], 10, _hoisted_2))
    }), 128))
  ]))
}