
import { defineComponent } from "vue";
import Card from "primevue/card";
import Tooltip from "primevue/tooltip";
import { mapGetters } from "vuex";
import LoadingSpinner from "../LoadingSpinner.vue";
export default defineComponent({
  components: {
    Card,
    LoadingSpinner,
  },
  directives: {
    tooltip: Tooltip,
  },
  props: {
    selected: Boolean,
    title: String,
    subtitle: String,
    statInfo: {
      type: Object,
      default: () => {
        return {};
      },
    },
    getter: Function,
  },
  data() {
    return {
      isLoading: false,
    };
  },
  computed: {
    ...mapGetters({
      getCustomer: "customer/getCust",
    }),
  },
  mounted() {
    this.fetchData();
  },
  methods: {
    async fetchData() {
      this.isLoading = true;
      this.getter && (await this.getter());
      this.isLoading = false;
    },
  },
});
