import { createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createBlock as _createBlock, toDisplayString as _toDisplayString, withModifiers as _withModifiers, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = { class: "grid mr-0 mt-0 ml-0 justify-content-center" }
const _hoisted_2 = { class: "col-12 p-0" }
const _hoisted_3 = { class: "accounting-tab" }
const _hoisted_4 = { class: "menutab-item" }
const _hoisted_5 = { class: "sales-tab p-0" }
const _hoisted_6 = { class: "grid align-content-center" }
const _hoisted_7 = { class: "col-12 text-center p-0 text-sm h-1rem" }
const _hoisted_8 = { class: "col-12 text-center p-0 text-sm h-1rem" }
const _hoisted_9 = {
  key: 0,
  class: "col-12 m-0 p-0"
}
const _hoisted_10 = { class: "accounting-tab pt-0 mt-0" }
const _hoisted_11 = { class: "accounting-tab pt-0 mt-0" }
const _hoisted_12 = { class: "align-self-center pr-2 pl-0" }
const _hoisted_13 = {
  key: 1,
  class: "col-12 flex p-0"
}
const _hoisted_14 = { class: "formgrid grid lg:flex align-items-center" }
const _hoisted_15 = { class: "menutab-item" }
const _hoisted_16 = {
  key: 0,
  class: "menutab-item"
}
const _hoisted_17 = {
  key: 0,
  class: "col pr-3"
}
const _hoisted_18 = { class: "sm:col-12 md:col-12 lg:col-12 ml-0 p-0" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_router_link = _resolveComponent("router-link")!
  const _component_Button = _resolveComponent("Button")!
  const _component_TabPanel = _resolveComponent("TabPanel")!
  const _component_TabView = _resolveComponent("TabView")!
  const _component_TopStatCards = _resolveComponent("TopStatCards")!
  const _component_router_view = _resolveComponent("router-view")!
  const _component_MessageBox = _resolveComponent("MessageBox")!
  const _component_RoverInput = _resolveComponent("RoverInput")!
  const _component_Dialog = _resolveComponent("Dialog")!
  const _component_PrintPickTicket = _resolveComponent("PrintPickTicket")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", _hoisted_2, [
        _createVNode(_component_TabView, {
          activeIndex: _ctx.getActiveMainTab,
          onTabChange: _ctx.handleMainTabChange,
          scrollable: true,
          class: "account-tabview"
        }, {
          default: _withCtx(() => [
            _createVNode(_component_TabPanel, null, {
              header: _withCtx(() => [
                _createElementVNode("div", _hoisted_3, [
                  _createVNode(_component_router_link, {
                    to: 
                  _ctx.$route.fullPath
                    .split('/')
                    .slice(0, _ctx.$route.fullPath.includes('gl') ? -2 : -1)
                    .join('/')
                ,
                    class: "line-height-4 accounting-tab-item"
                  }, {
                    default: _withCtx(() => _cache[4] || (_cache[4] = [
                      _createElementVNode("span", { class: "align-self-center pr-3 pl-0" }, "Dashboard", -1)
                    ])),
                    _: 1
                  }, 8, ["to"])
                ])
              ]),
              default: _withCtx(() => [
                _createVNode(_component_TabView, {
                  scrollable: true,
                  class: "customers-menu-tabview sales-menu-tabview tabview-panels-hidden top-navigation-tabview xl:hidden block",
                  activeIndex: _ctx.getActiveInnerTab,
                  onTabChange: _ctx.handleStatCardTabChange
                }, {
                  default: _withCtx(() => [
                    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.filteredAccountItems, (tab) => {
                      return (_openBlock(), _createBlock(_component_TabPanel, {
                        key: tab.label
                      }, {
                        header: _withCtx(() => [
                          _createElementVNode("div", _hoisted_4, [
                            _createVNode(_component_router_link, {
                              to: tab.to,
                              class: "menutab-item-link"
                            }, {
                              default: _withCtx(() => [
                                _createVNode(_component_Button, {
                                  label: tab.label,
                                  class: "menutab-button"
                                }, null, 8, ["label"])
                              ]),
                              _: 2
                            }, 1032, ["to"])
                          ])
                        ]),
                        _: 2
                      }, 1024))
                    }), 128))
                  ]),
                  _: 1
                }, 8, ["activeIndex", "onTabChange"]),
                _createVNode(_component_TopStatCards, {
                  ref: "topStatCards",
                  items: 
              Object.entries(_ctx.filteredAccountItems).map((item) => item[1])
            
                }, null, 8, ["items"])
              ]),
              _: 1
            }),
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.getAccountingInquiryMap, (tab) => {
              return (_openBlock(), _createBlock(_component_TabPanel, {
                key: 
            tab.record.ap_id ?? tab.record.transaction_id ?? tab.record.so_id
          
              }, {
                header: _withCtx(() => [
                  _createElementVNode("div", _hoisted_5, [
                    _createVNode(_component_router_link, {
                      to: `/accounting/${tab.accountType}/${
                  tab.accountType === 'accounts-payable'
                    ? tab.record.ap_id.replace('#', '-')
                    : tab.accountType === 'orders'
                      ? tab.record.so_id.replace('#', '-')
                      : tab.record.transaction_id?.replace('#', '-') +
                        '/gl-transaction'
                }`,
                      class: "line-height-4 sales-tab-item"
                    }, {
                      default: _withCtx(() => [
                        _createElementVNode("div", _hoisted_6, [
                          _createElementVNode("span", _hoisted_7, _toDisplayString(_ctx.getTabTitle(tab)), 1),
                          _createElementVNode("span", _hoisted_8, _toDisplayString(_ctx.formatTabId(tab)) + " " + _toDisplayString(_ctx.hasChanged(tab) ? "*" : ""), 1)
                        ])
                      ]),
                      _: 2
                    }, 1032, ["to"]),
                    _createVNode(_component_Button, {
                      icon: "pi pi-times",
                      class: "p-button-text p-0 m-0 pb-1",
                      title: `Close ${_ctx.getTabTitle(tab)} ${_ctx.formatTabId(tab)}`,
                      onClick: _withModifiers(($event: any) => (_ctx.handleRemoveAccountingTab(tab)), ["stop"])
                    }, null, 8, ["title", "onClick"])
                  ])
                ]),
                default: _withCtx(() => [
                  (tab.accountType === 'accounts-payable')
                    ? (_openBlock(), _createElementBlock("div", _hoisted_9, [
                        _createVNode(_component_TabView, {
                          "active-index": _ctx.activeLineItemTab,
                          onTabChange: ($event: any) => (_ctx.handleLineItemTabChange($event, tab)),
                          scrollable: true,
                          class: "account-tabview line-items-tabview"
                        }, {
                          default: _withCtx(() => [
                            _createVNode(_component_TabPanel, null, {
                              header: _withCtx(() => [
                                _createElementVNode("div", _hoisted_10, [
                                  _createVNode(_component_router_link, {
                                    to: '/accounting/accounts-payable/' + tab.record.ap_id,
                                    class: "line-height-4 accounting-tab-item ml-0"
                                  }, {
                                    default: _withCtx(() => _cache[5] || (_cache[5] = [
                                      _createElementVNode("span", { class: "align-self-center pr-2 pl-0" }, "Information", -1)
                                    ])),
                                    _: 2
                                  }, 1032, ["to"])
                                ])
                              ]),
                              _: 2
                            }, 1024),
                            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.getLineItemsMap(tab.record.ap_id), (item) => {
                              return (_openBlock(), _createBlock(_component_TabPanel, {
                                key: item.li,
                                class: "pt-0"
                              }, {
                                header: _withCtx(() => [
                                  _createElementVNode("div", _hoisted_11, [
                                    _createVNode(_component_router_link, {
                                      to: 
                        '/accounting/accounts-payable/' +
                        tab.record.ap_id +
                        '/line-item/' +
                        item.li
                      ,
                                      class: "line-height-4 accounting-tab-item ml-0"
                                    }, {
                                      default: _withCtx(() => [
                                        _createElementVNode("span", _hoisted_12, "Line # " + _toDisplayString(item.li), 1)
                                      ]),
                                      _: 2
                                    }, 1032, ["to"]),
                                    _createVNode(_component_Button, {
                                      icon: "pi pi-times",
                                      class: "p-button-text p-0 m-0 pb-1",
                                      onClick: _withModifiers(($event: any) => (
                        _ctx.handleRemoveLineTab({ ...item }, tab.record.ap_id)
                      ), ["stop"])
                                    }, null, 8, ["onClick"])
                                  ])
                                ]),
                                _: 2
                              }, 1024))
                            }), 128))
                          ]),
                          _: 2
                        }, 1032, ["active-index", "onTabChange"])
                      ]))
                    : _createCommentVNode("", true),
                  (_ctx.areInnerTabsVisible(tab))
                    ? (_openBlock(), _createElementBlock("div", _hoisted_13, [
                        _createElementVNode("div", _hoisted_14, [
                          _createElementVNode("div", {
                            class: _normalizeClass({
                  'col-10 xl:col-11 grid mt-0 pt-0': _ctx.isSaveButtonVisible,
                  'col-12 pt-0 grid mt-0 pt-0': !_ctx.isSaveButtonVisible,
                })
                          }, [
                            _createVNode(_component_TabView, {
                              scrollable: true,
                              class: "customers-menu-tabview p-0 border-round border-none ml-0 account-tabview accounting-inner-tabs",
                              "active-index": 
                    _ctx.getActiveInnerTab(
                      tab.record.ap_id ??
                        tab.record.transaction_id ??
                        tab.record.id ??
                        0,
                    )
                  ,
                              onTabChange: ($event: any) => (_ctx.handleInnerTabChange($event, tab))
                            }, {
                              default: _withCtx(() => [
                                _createVNode(_component_TabPanel, null, {
                                  header: _withCtx(() => [
                                    _createElementVNode("div", _hoisted_15, [
                                      _createVNode(_component_router_link, {
                                        class: "menutab-item-link",
                                        to: `${_ctx.innerTabs(tab.accountType)[0].prefix}/${
                            tab.record.ap_id ?? tab.record.transaction_id
                          }/${_ctx.innerTabs(tab.accountType)[0].suffix}`
                                      }, {
                                        default: _withCtx(() => [
                                          _createVNode(_component_Button, {
                                            label: _ctx.innerTabs(tab.accountType)[0].label,
                                            class: "menutab-button inner-tab"
                                          }, null, 8, ["label"])
                                        ]),
                                        _: 2
                                      }, 1032, ["to"])
                                    ])
                                  ]),
                                  _: 2
                                }, 1024),
                                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.innerTabs(tab.accountType).slice(1), (option) => {
                                  return (_openBlock(), _createBlock(_component_TabPanel, {
                                    key: option.label
                                  }, {
                                    header: _withCtx(() => [
                                      (_ctx.isInnerTabVisible(tab))
                                        ? (_openBlock(), _createElementBlock("div", _hoisted_16, [
                                            _createVNode(_component_router_link, {
                                              class: "menutab-item-link",
                                              to: `${option.prefix}/${
                            tab.record.ap_id ?? tab.record.transaction_id
                          }/${option.suffix}`
                                            }, {
                                              default: _withCtx(() => [
                                                _createVNode(_component_Button, {
                                                  label: option.label,
                                                  class: "menutab-button inner-tab"
                                                }, null, 8, ["label"])
                                              ]),
                                              _: 2
                                            }, 1032, ["to"])
                                          ]))
                                        : _createCommentVNode("", true)
                                    ]),
                                    _: 2
                                  }, 1024))
                                }), 128))
                              ]),
                              _: 2
                            }, 1032, ["active-index", "onTabChange"])
                          ], 2),
                          (_ctx.isSaveButtonVisible)
                            ? (_openBlock(), _createElementBlock("div", _hoisted_17, [
                                _createVNode(_component_Button, {
                                  label: "Save",
                                  class: "w-full",
                                  disabled: _ctx.hasChanged(tab) === false
                                }, null, 8, ["disabled"])
                              ]))
                            : _createCommentVNode("", true)
                        ])
                      ]))
                    : _createCommentVNode("", true)
                ]),
                _: 2
              }, 1024))
            }), 128))
          ]),
          _: 1
        }, 8, ["activeIndex", "onTabChange"])
      ]),
      _createElementVNode("div", _hoisted_18, [
        _createVNode(_component_router_view, { onReleaseOrder: _ctx.handleReleaseOrder }, null, 8, ["onReleaseOrder"])
      ])
    ]),
    _createVNode(_component_MessageBox, {
      showDialog: _ctx.showConfirmDeleteTab,
      icon: "WARNING",
      header: _ctx.getConfirmDeleteTabMessage(),
      message: "Are you sure you want to close the tab without saving the changes?",
      primaryButton: "Yes",
      cancelButton: "Cancel",
      onPrimaryButtonClick: _ctx.handleCloseSaveFieldServiceTab,
      onCancelButtonClick: _ctx.handleCancelSaveFieldServiceTab
    }, null, 8, ["showDialog", "header", "onPrimaryButtonClick", "onCancelButtonClick"]),
    _createVNode(_component_Dialog, {
      visible: _ctx.showReleaseDialog,
      "onUpdate:visible": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.showReleaseDialog) = $event)),
      modal: "",
      header: "Release Order"
    }, {
      footer: _withCtx(() => [
        _createVNode(_component_Button, {
          loading: _ctx.loadingReleaseOrder,
          label: "Release",
          onClick: _ctx.releaseOrder
        }, null, 8, ["loading", "onClick"])
      ]),
      default: _withCtx(() => [
        _cache[6] || (_cache[6] = _createElementVNode("div", null, [
          _createElementVNode("p", null, " Are you sure you want to release this order? Please select reason for release. ")
        ], -1)),
        _createVNode(_component_RoverInput, {
          field: _ctx.salesOrderReleaseReasonField,
          modelValue: _ctx.order.release_code,
          "onUpdate:modelValue": [
            _cache[0] || (_cache[0] = ($event: any) => ((_ctx.order.release_code) = $event)),
            _cache[1] || (_cache[1] = ($event: any) => (_ctx.order.release_code = $event))
          ]
        }, null, 8, ["field", "modelValue"])
      ]),
      _: 1
    }, 8, ["visible"]),
    _createVNode(_component_PrintPickTicket, {
      visible: _ctx.showPickTicketPrinterDialog,
      salesOrder: _ctx.order,
      onHandleClose: _cache[3] || (_cache[3] = ($event: any) => (_ctx.showPickTicketPrinterDialog = false))
    }, null, 8, ["visible", "salesOrder"])
  ], 64))
}